.nav{
	display: flex;
	justify-content: space-between;
	padding: 1rem 4rem;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1000;
	&.scrolled{
		background-color: rgba(15,15,15,0.9);
	}
	.menu{
		display: none;
	}
	.nav-items{
		display: flex;
		align-items: center;
		.nav-item{
			text-transform: uppercase;
			padding: 1rem 0;
			color: white;
			margin-left: 2rem;
			font-weight: 500;
			cursor: pointer;
			border-bottom: 2px solid transparent;
			&.active{
				color: $highlight;
				border-color: $highlight;
				font-weight: 500;
				&:hover{
					color: $highlightDark;
					border-color: $highlightDark;
				}
			}
			&:hover{
				color: $highlightDark;
			}
		}
		.languages{
			margin: -2px 0 0 2rem;
			padding-left: 1rem;
			border-left: 2px solid white;
			cursor: pointer;
			span{
				border: 1px solid transparent;
				padding: 0.5rem;
				margin-left: 1rem;
				transition: all 0.35s;
				&.active{
					color: $highlight;
					border-color: $highlight;
				}
				&:not(.active):hover{
					color: $highlightDark;
				}
			}
		}
	}
}
