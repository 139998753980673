#contact{
	.message{
		border: 1px solid white;
		padding: 0.5rem;
		text-align: center;
		font-weight: bold;
		&.success{
			color: green;
			border-color: green;
		}
		&.error{
			color: #cc1c1c;
			border-color: #cc1c1c;
		}
	}
	.header{
		background-image: url("../../img/contact.jpg");
		background-size: cover;
		background-position: center;
		padding: 2rem 0;
	}
	h4{
		font-size: 3rem;
		text-align: center;
		color: $highlight;
		margin: 0 0 2rem;
	}
	.form{
		display: flex;
		margin: 0 -1rem 2rem;
		flex-wrap: wrap;
		label{
			margin: 0 1rem;
			display: flex;
			flex-direction: column;
			padding: 1rem 0 0;
			input, textarea{
				background-color: #333;
				border: none;
				color: white;
				&:focus{
					outline: none;
				}
			}
			input{
				height: 2.5rem;
				padding: 0 1rem;
			}
			span{
				margin: 0 0 0.5rem;
			}
			&.text{
				flex: 0 0 calc(50% - 2rem);
			}
			&.textarea{
				flex: 0 0 calc(100% - 2rem);
				textarea{
					padding: 0.75rem 1rem;
					height: 6rem;
				}
			}
			&.checkbox {
				position: relative;
				padding-top: 0;
				cursor: pointer;
				user-select: none;
				flex: 0 0 100%;
				flex-direction: row;
				align-items: center;
				margin: 2rem 1rem;
				span{
					margin: 0;
				}
				input {
					position: absolute;
					opacity: 0;
					cursor: pointer;
					height: 0;
					width: 0;
					&:checked ~ .checkmark {
						background-color: #2196F3;
						&:after {
							display: block;
						}
					}
				}
				.checkmark {
					height: 1.5rem;
					width: 1.5rem;
					position: relative;
					min-width: 1.5rem;
					margin-right: 0.5rem;
					background-color: #eee;
					&:after {
						content: "";
						position: absolute;
						display: none;
						left: 9px;
						top: 5px;
						width: 5px;
						height: 10px;
						border: solid white;
						border-width: 0 3px 3px 0;
						transform: rotate(45deg);
					}
				}
				&:hover input ~ .checkmark {
					background-color: #ccc;
				}
			}

			&.captcha{
				flex: 1;
				padding: 0;
				.captcha {
					position: relative;
					flex: 1;
					input{
						flex: 1;
						width: 100%;
					}
					div{
						position: absolute;
						top: 0;
						right: 0;
						canvas{
							height: 2.5rem;
						}
					}
					svg{
						position: absolute;
					}
				}
			}
		}
		.btn{
			margin: auto 1rem 0;
			padding: 0 4rem;
			height: 2.5rem;
		}
	}
}
