$background: #1a1a1a;
$tile: #222;
$highlight: #24afff;
$highlightDark: #006ba7;
$m8werk: #2086C1;
$ander5: #D0B736;
$finholding: #2086C1;
$turnworks: #ffff00;
$elapporate: #B7292A;
$immosaar: #028137;
