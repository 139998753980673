@import "components/fonts";
@import "components/colors";
*{
	box-sizing: border-box;
}
html, body{
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}
body {
	font-family: "Encode Sans", sans-serif;
	background-color: $background;
	color: white;
	display: flex;
	flex: 1;
	flex-direction: column;
	min-width: 400px;
}
#app{
	display: flex;
	flex: 1;
	flex-direction: column;
	white-space: pre-line;
	@import "components/navigation";
	@import "components/welcome";
	@import "components/about";
	@import "components/services";
	@import "components/contact";
	@import "components/footer";
	.App{
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}
	.loader {
		width: 48px;
		height: 48px;
		border: 5px solid #666;
		border-bottom-color: $highlight;
		border-radius: 50%;
		display: inline-block;
		box-sizing: border-box;
		animation: rotation 1s linear infinite;
	}
	@keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	section{
		display: flex;
		flex: 1;
		//min-height: 100vh;
		flex-direction: column;
		position: relative;
	}
	.container{
		padding: 4rem;
		max-width: 70rem;
		margin: 0 auto;
		position: relative;
	}
	.logo{
		height: 3rem;
	}
	.btn{
		background: none;
		color: white;
		border: 2px solid $highlight;
		padding: 1.25rem 2.5rem;
		text-transform: uppercase;
		cursor: pointer;
		transition: all 0.35s;
		&:hover{
			background-color: rgba(0,0,0,0.5);
			border-color: $highlightDark;
		}
		&.primary{

		}
	}
	p{
		font-weight: 400;
		color: #CCC;
		font-size: 0.85rem;
	}
	h2{
		margin: 0;
		display: flex;
		font-size: 3rem;
		font-weight: 900;
		text-transform: uppercase;
		flex-direction: column;
		align-items: center;
	}
	.title-line{
		height: 3rem;
		width: 0.2rem;
		background-color: $highlight;
		margin: 0 auto 1rem;
	}
	.divider{
		width: 3rem;
		height: 0.1rem;
		background-color: $highlight;
		margin: 2rem 0;
	}
	.tiles{
		display: flex;
		margin: 0 -1rem;
		&.full{
			margin: 0;
			flex-wrap: wrap;
			.tile{
				margin: 0;
				flex: 0 0 33.3333%;
			}
		}
		.tile{
			margin: 0 1rem;
			flex: 1;
			padding: 2rem;
			.tile-inner{
				background-color: $tile;
				padding: 2rem;
			}
			svg{
				font-size: 3rem;
				path{
					fill: $highlight;
				}
			}
			h3{
				font-size: 1.4rem;
				font-weight: 600;
				&:after{
					content: "";
					width: 3rem;
					height: 0.2rem;
					display: block;
					background-color: $highlight;
					margin-top: 2rem;
				}
			}
			img{
				max-height: 6rem;
				max-width: 8rem;
				width: fit-content;
			}
		}
	}
	.popup{
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: 2000;
		display: flex;
		align-items: center;
		justify-content: center;
		.close{
			position: absolute;
			top: 2rem;
			right: 2rem;
			cursor: pointer;
			&:hover{
				svg{
					path:not([fill="none"]){
						fill: $highlight;
					}
				}
			}
			svg{
				width: 3rem;
				height: 3rem;
				z-index: 10000;
				path:not([fill="none"]){
					transition: all 0.35s;
					fill: white;
				}
			}
		}
		.shadow{
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-color: rgba(0,0,0,0.5);
		}
		.popup-inner{
			width: 60%;
			height: 60%;
			background-color: $tile;
			padding: 2rem;
			box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
		}
	}
	a{
		color: $highlight;
		transition: all 0.35s;
		&:hover{
			color: $highlightDark;
		}
	}
	.bg-wrapper{
		position: absolute;
		width: 100%;
		background-image: url("../img/welcome.jpg");
		background-position: center;
		background-attachment: fixed;
		> div{
			overflow: visible !important;
		}
		&.full{
			height: 100vh;
		}
		&.h80{
			height: 80%;
		}
		&.h60{
			height: 60vh;
		}
	}
	.sides{
		display: flex;
		margin: 6rem -1rem 10rem;
		> div{
			margin: 0 1rem;
			flex: 0 0 calc(50% - 2rem);
			display: flex;
			flex-direction: column;
			justify-content: center;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			p{
				margin: 0;
			}
		}
	}
	img.small{
		display: none;
	}
}
@media (max-width: 1140px){
	#app{
		img.small{
			display: initial;
		}
		img.big{
			display: none;
		}
		.container{
			padding: 2rem;
		}
		.btn{
			padding: 2vw 4vw;
		}
		#welcome{
			.slogan{
				margin-top: 15%;
				h1{
					font-size: 10vw;
					line-height: 10vw;
					+h1{
						font-size: 10vw;
					}
				}
			}
			.tiles{
				flex-direction: column;
				margin-top: 10%;
				.tile{
					margin-bottom: 2rem;
					&:last-child{
						margin-bottom: 0;
					}
					.tile-inner{
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						svg{
							position: absolute;
							height: 2rem;
							width: 2rem;
						}
						h3{
							margin: 0;
							padding-left: 4rem;
							height: 3rem;
							&:after{
								margin-left: -4rem;
								width: 6rem;
								margin-top: 1rem;
								height: 2px;
							}
						}
						p{
							flex: 0 0 100%;
							margin-bottom: 0;
						}
					}
				}
			}
		}
		.sides{
			flex-direction: column;
			margin-top: 2rem;
			margin-bottom: 4rem;
			div{
				&:first-child{
					margin-bottom: 1rem;
				}
				> img{
					width: calc(100% + 4rem);
					height: initial;
					object-fit: contain;
					margin: 0 -2rem;
				}
			}
		}
		.popup{
			.shadow{
				background: rgba(0,0,0,0.8);
			}
			.popup-inner{
				width: 90%;
				height: 80%;
			}
		}
		.footer{
			padding: 0;
			.sides{
				align-items: center;
				*{
					text-align: center;
				}
				.side{
					align-items: center;
				}
			}
			.copyright{
				padding: 0 0 1rem;
				.container{
					.side{
						+ .side{
							margin-top: 0.5rem;
						}
					}
				}
			}
		}
		#about{
			.tiles{
				padding: 2rem;
				display: none;
				.tile{
					flex: 0 0 100%;
					background: rgba(30,30,30,0.9);
					position: relative;
					z-index: 2;
					+ .tile{
						margin-top: 2rem;
					}
				}
				&:after{
					left: 0;
					top: 0;
					width: 100%;
					opacity: 0.1;
				}
			}
		}
		#contact{
			.form{
				label{
					&.captcha,
					&.text{
						flex-basis: calc(100% - 2rem);
					}
				}
				.btn{
					flex: 1;
					width: 100%;
					margin-top: 2rem;
				}
			}
		}
		#services{
			.sides{
				.side{
					//flex-direction: row;
					align-items: center;
					min-height: initial;
					&:not(.image){
						padding: 0;
					}
					.logo{
						margin: 0 2rem 0 0;
					}
					&.image{
						display: none;
					}
				}
			}
		}
		.nav{
			padding: 1rem 2rem;
			.nav-items{
				display: none;
				animation: fadeInMenu 1s forwards;
				overflow: hidden;
				z-index: -1;
				.languages{
					border: none;
					position: absolute;
					top: 8rem;
					right: 2rem;
				}
				&.show{
					display: flex;
					position: fixed;
					flex-direction: column;
					top: 0;
					left: 0;
					height: 100%;
					background: rgba(20,20,20,0.95);
					width: 100%;
					justify-content: center;
					.nav-item{
						width: 100%;
						margin: 0;
						padding: 1rem 2rem;
						border: none;
						min-width: 200px;
						&.active{
							border: none;
						}
					}
				}
			}
			.menu{
				display: flex;
				cursor: pointer;
				svg{
					height: 2rem;
					width: 2rem;
					path{
						transition: all 0.35s;
					}
				}
				&:hover{
					svg{
						path:not([fill="none"]){
							fill: $highlight;
						}
					}
				}
			}
		}
	}
}
@keyframes fadeInMenu{
	from{width: 0;}
	to{width: 100%;}
}
