#about{
	.container{
		padding-bottom: 0;
	}
	.tiles{
		background-color: #222;
		position: relative;
		padding-right: 25%;
		&:after{
			content:"";
			background-image: url("../../img/companies.jpg");
			background-position: center;
			width: 25%;
			height: 100%;
			position: absolute;
			right: 0;
			background-size: cover;
		}
		.tile{
			background: linear-gradient(45deg, #111 0%, #222 100%);
			justify-content: space-between;
			display: flex;
			flex-direction: column;
			//flex: 0 0 25%;
			//&.m8werk-consulting{
			//	background-color: rgba(0,28,44,0.8);
			//}
			//&.ander5{
			//	background-color: rgba(82, 72, 15, 0.8);
			//}
			//&.turnworks{
			//	background-color: rgba(20, 20, 20, 0.8);
			//}
			//&.elapporate{
			//	background-color: rgba(66, 10, 10, 0.8);
			//}
			//&.finholding{
			//	background-color: rgba(10, 43, 62, 0.8);
			//}
			//&.immosaar{
			//	background-color: rgba(10, 38, 16, 0.8);
			//}
		}
	}
	.sides{
		.logo{
			height: 3rem;
			margin: 0 auto 2rem 0;
			width: auto;
		}
	}
}
