#services{
	position: relative;
	&:before{
		display: block;
		content: "";
		position: absolute;
		background-image: url("../../img/bg.jpg");
		background-size: cover;
		background-attachment: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.05;
	}
	.side-wrapper{
		.container{
			padding-top: 0;
			padding-bottom: 0;
		}
		&:nth-child(2n-1){
			background-color: #222;
		}

		&:nth-of-type(2n){
			.side{
				&:nth-child(1){
					order: 1;
				}
			}
		}
	}
	.sides{
		margin: 2rem -1rem;
		flex-wrap: wrap;

		+ .sides{
			//border-top: 1px solid #666;
			//padding-top: 2rem;
		}
		> div img{
			//height: 24rem;
		}
		.line-wrapper{
			order: 100;
			flex: initial;
			margin: 2rem auto;
			display: initial;
			.line{
				width: 0.2rem;
				height: 3rem;
				margin: 0 auto;
				background-color: $highlight;
				flex: 0 0 0.2rem;
			}
		}
		&.m8werk-consulting{
			.btn{border-color: $m8werk;}
		}
		&.ander5{
			.btn{border-color: $ander5;}
		}
		&.turnworks{
			.btn{border-color: $turnworks;}
		}
		&.elapporate{
			.btn{border-color: $elapporate;}
		}
		&.finholding{
			.btn{border-color: $finholding;}
		}
		&.immosaar{
			.btn{border-color: $immosaar;}
		}
		.side{
			padding: 2rem 0;
			min-height: 20rem;
			justify-content: center;
			position: relative;
			&.image > div{
				height: 100%;
				img{
					padding: 1rem;
					border-width: 1px;
					border-style: solid;
					border-image: linear-gradient(235deg, #666, $background 20%, $background 80%, #666) 1;
				}
			}
			&:not(.image){
				padding: 4rem 0;
			}
			>div{
				display: flex;
				flex-direction: column;
			}
			.image-wrapper{
				position: absolute;
				overflow: hidden;
				height: 100%;
				width: 100%;
				background-size: cover;
				background-position: center;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.logo{
				max-width: 12rem;
				width: auto;
				height: auto;
				//max-height: 8rem;
				margin: 0 0 2rem;
			}
			.btn{
				margin: 2rem 0 0 auto;
				padding: 0.5rem 2rem;
			}
			p:last-of-type{
				//&:after{
				//	content: "";
				//	display: block;
				//	width: 3rem;
				//	height: 0.2rem;
				//	background-color: white;
				//	margin-top: 2rem;
				//}
			}
		}
	}
}
