#welcome{
	.slogan{
		margin-top: 25%;
		.small{
			color: $highlight;
			font-size: 1rem;
			font-weight: 600;
			margin: 0;
		}
		h1{
			font-size: 6rem;
			line-height: 5.5rem;
			font-weight: 900;
			margin: 2rem 0 0;
			text-transform: uppercase;
			+ h1{
				margin: 0 0 2rem;
				color: $highlight;
				font-size: 6rem;
			}
		}
	}
	.tiles{
		margin-top: 25%;
		.tile{
			padding: 0;
			> div{
				height: 100%;
				.tile-inner{
					height: 100%;
				}
			}
		}
	}
}
