.footer{
	padding: 2rem;
	background-color: #111;
	.sides{
		margin: 0 -1rem;
		align-items: flex-start;
		.side{
			flex: 1;
			&:first-child{
				flex: 2;
			}
			img{
				width: 8rem;
				height: auto;
				margin: 0 0 1rem;
			}
			strong{
				white-space: nowrap;
			}
			p{
				margin: 0 0 0.5rem;
			}
			a, p{
				color: #aaa;
				text-decoration: none;
			}
			h4{
				margin: 0 0 0.5rem;
				color: $highlight;
			}
		}
		.socials{
			font-size: 3rem;
		}
	}
	.copyright{
		.container{
			padding-top: 0;
			padding-bottom: 0;
			.sides{
				padding: 1rem 0 0;
				margin: 0;
				border-top: 1px solid #222;
				.side{
					padding: 0;
					margin: 0;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					+ .side{
						justify-content: flex-end;
						p{
							margin-left: 1rem;
						}
					}
					p{
						color: #666;
						margin: 0;
						&.link{
							cursor: pointer;
							transition: all 0.35s;
							&:hover{
								color: $highlight;
							}
						}
					}
				}
			}
		}
	}
}
